import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosRequestConfig } from 'axios';

import { GO_API_URL, NODE_API_URL } from '@/const';

const svGoApiClient = axios.create({
	// withCredentials: true,
	baseURL: GO_API_URL || 'http://localhost:3010/v1',
	timeout: 30000, // 30 seconds timeout,
	// headers: {
	// 	Authorization: `Bearer ${process.env.REACT_APP_TEMP_API_KEY}`,
	// },
});

const svCopilotApiClient = axios.create({
	baseURL: NODE_API_URL || 'http://localhost:3006/v1',
	timeout: 30000, // 30 seconds timeout,
});

svGoApiClient.interceptors.request.use((config) => {
	try {
		const accessToken = localStorage?.getItem('sv_go_key');

		const parsedToken: string | null = accessToken ? accessToken : '';

		if (parsedToken) {
			config!.headers!.Authorization = `Bearer ${parsedToken}`;
		}
	} catch (error) {
		console.log('axios request interceptor: ', error);
	}

	return config;
});

svCopilotApiClient.interceptors.request.use((config) => {
	try {
		const accessToken = localStorage?.getItem('sv_go_key');

		const parsedToken: string | null = accessToken ? accessToken : '';

		if (parsedToken) {
			config!.headers!.Authorization = `Bearer ${parsedToken}`;
		}
	} catch (error) {
		console.log('axios request interceptor: ', error);
	}

	return config;
});

interface IOptions {
	page?: number;
	limit?: number;
}

interface ICopilotVideosOptions extends IOptions {
	onlyUser?: boolean;
	userId?: number;
	sortBy?: 'dateProcessed';
	sortOrder?: 'asc' | 'desc';
}

interface IBatchProcess {
	collectionName?: string;
	actions: string[];
	tags?: string[];
	urls: string[];
	limit?: number;
}

interface IFeedbackData {
	feedback: string;
	rate: any;
	user_id: number;
	url: string;
	api_key: string;
}

export interface CopilotHistoryResponse {
	data: any;
}

export interface CopilotHistory {
	results: CopilotHistoryPageItem[];
	total: number;
}

export interface CopilotHistoryPageItem {
	id: number;
	url: string;
	platform: string;
	title: string;
	description: string;
	thumbnail: string;
	status: number;
	createdAt: Date;
	favourite: boolean;
}

function getAllProcesses({ page = 1, limit = 10 }: IOptions) {
	return svGoApiClient.get<any>(
		`/process?page=${page}&limit=${limit}&type=vod`,
	);
}

function getAllCopilotVideos({
	page = 1,
	limit = 10,
	onlyUser = false,
	userId,
	sortBy,
	sortOrder,
}: ICopilotVideosOptions) {
	const queryParams = onlyUser
		? `page=${page}&limit=${limit}&sortOrder=${sortOrder}&onlyUser=true&userId=${userId}`
		: `page=${page}&limit=${limit}&sortOrder=${sortOrder}`;
	// /copilot/history
	return svGoApiClient.get<CopilotHistoryResponse>(
		`/copilot/history?${queryParams}`,
	);
}

function getSingleCopilotVideo(url: string) {
	return svGoApiClient.get<CopilotHistoryResponse>(`/copilot?url=${url}`);
}

function getNewApiKey() {
	return svGoApiClient.get<any>(`/gen-api-key`);
}

function getProcessesById(id: string) {
	return svGoApiClient.get<any>(`/process/${id}`);
}

function createBatchProcess(batchProcess: IBatchProcess) {
	return svGoApiClient.post<any>(`/video`, batchProcess as AxiosRequestConfig);
}

function sendFeedback({
	feedback,
	rate,
	user_id,
	url,
	api_key,
}: IFeedbackData) {
	return svGoApiClient.post<any>(
		`/copilot/review`,
		{
			comment: feedback,
			vote: rate,
			user_id,
			content_url: url ?? null,
		} as AxiosRequestConfig,
		{
			headers: {
				Authorization: `Bearer ${api_key}`,
			},
		},
	);
}

function getReportIdByPlatformHandle(platformHandle: string) {
	return svGoApiClient.get<any>(
		`/reports/id/byPlatformHandle?q=${platformHandle}`,
	);
}

export function useGetReportIdByPlatformHandle(
	platformHandle: string,
	enabled = false,
) {
	return useQuery(
		['reportId', platformHandle],
		() => getReportIdByPlatformHandle(platformHandle),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			staleTime: 1000 * 60 * 5,
			enabled,
		},
	);
}

export async function downloadBatchJsonData(id: string) {
	return await svGoApiClient.get(`/process/${id}/content/json`, {
		responseType: 'blob',
	});
}

export function useGetAllCustomerProcesses(
	{ page = 1, limit = 10 }: IOptions,
	isRefreshEnabled: boolean = false,
) {
	return useQuery(
		['processes', page, limit],
		() =>
			getAllProcesses({
				page,
				limit,
			}),
		{
			keepPreviousData: false,
			refetchOnWindowFocus: false,
			staleTime: 1000 * 60 * 5,
			refetchInterval: isRefreshEnabled ? 1000 * 10 : false,
		},
	);
}

export function useGetAllCopilotVideos(
	options: ICopilotVideosOptions,
	isRefreshEnabled: boolean = false,
) {
	return useQuery(['copilot-videos'], () => getAllCopilotVideos(options), {
		keepPreviousData: false,
		refetchOnWindowFocus: false,
		staleTime: 1000 * 60 * 5,
		// refetchInterval: isRefreshEnabled ? 1000 * 10 : false,
	});
}

export function useGetSingleCopilotVideo(
	url: string,
	enabled: boolean = false,
) {
	return useQuery(['copilot-video', url], () => getSingleCopilotVideo(url), {
		keepPreviousData: false,
		refetchOnWindowFocus: false,
		staleTime: 1000 * 60 * 5,
		enabled,
	});
}

export function useGetBatchProcessesById(id: string) {
	return useQuery(['processes', id], () => getProcessesById(id), {
		keepPreviousData: true,
		refetchOnWindowFocus: false,
		staleTime: 1000 * 60 * 5,
		enabled: typeof id !== 'undefined',
	});
}

export function useCreateBatchProcess() {
	const queryClient = useQueryClient();

	return useMutation(createBatchProcess, {
		onSuccess: () => {
			queryClient.invalidateQueries();
		},
	});
}

export function useSendFeedback() {
	return useMutation(sendFeedback);
}

export function useDownloadBatchProcessJson(id: string) {
	return useQuery(['batchJsonData', id], () => downloadBatchJsonData(id), {
		keepPreviousData: true,
		refetchOnWindowFocus: false,
		enabled: typeof id !== 'undefined',
	});
}

export function useGetNewApiKey() {
	return useQuery(['api-key'], () => getNewApiKey(), {
		refetchOnWindowFocus: false,
		enabled: false,
	});
}
