import { useContext, useEffect, useState } from 'react';

import { BookOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Checkbox, Flex, Space, Spin, Table, Tooltip } from 'antd';

import { useGetAllCopilotVideos } from '@/api/batch_process';
import { PageContext } from '@/providers/PageContextProvider';

import { IPagination } from '../video-intelligence-on-demand';
import { columns } from './data';

export default function VideoInsightsCoPilot() {
	const { setPageTitle, openDrawer, setIsCopilotCreditsCounterVisible } =
		useContext(PageContext);
	const [pagination, setPagination] = useState<IPagination>({
		page: 1,
		pageCount: 1,
		total: 0,
		limit: 10,
	});
	const [filterUserInsights, setFilterUserInsights] = useState(true);

	const [sortBy, setSortBy] = useState<'dateProcessed'>('dateProcessed');
	const [sortOrder, setSortOrder] = useState<'desc' | 'asc'>('desc');

	const { data, isLoading, isFetching, refetch } = useGetAllCopilotVideos({
		page: pagination.page,
		limit: pagination.limit,
		onlyUser: filterUserInsights,
		sortBy,
		sortOrder,
		userId: 1,
	});

	const total = data?.data?.data?.total ?? 1;
	const items = data?.data?.data?.results ?? [];

	useEffect(() => {
		setPageTitle('Video insights co-pilot');
		setIsCopilotCreditsCounterVisible(true);

		return () => {
			setPageTitle('');
			setIsCopilotCreditsCounterVisible(false);
		};
	}, []);

	useEffect(() => {
		refetch();
	}, [filterUserInsights, sortOrder, pagination]);

	const onTableChange = (__: any, _: any, sorter: any) => {
		const { field = 'first_name', order = 'ascend' } = sorter;

		if (field) {
			setSortBy(field);
			// setSearchParams((searchParams) => {
			// 	searchParams.set('sortBy', field);
			// 	return searchParams;
			// });
		}

		if (order) {
			setSortOrder(order === 'ascend' ? 'asc' : 'desc');
			// setSearchParams((searchParams) => {
			// 	searchParams.set('sortOrder', order === 'ascend' ? 'asc' : 'desc');
			// 	return searchParams;
			// });
		}
	};

	return (
		<Spin spinning={isLoading || isFetching}>
			<div>
				<Flex
					align="center"
					justify="space-between"
					style={{ marginBottom: '1.5rem' }}
				>
					<h2>Copilot videos history</h2>

					<Space>
						<Tooltip title="Refresh">
							<Button onClick={() => refetch()} icon={<ReloadOutlined />} />
						</Tooltip>
					</Space>
				</Flex>

				<Flex vertical gap={16} style={{ width: '100%' }}>
					<Checkbox
						checked={filterUserInsights}
						onChange={() => setFilterUserInsights((prev) => !prev)}
					>
						My Co-pilot insights
					</Checkbox>

					<Table
						loading={isLoading || isFetching}
						// style={{ width: '100%' }}
						onChange={onTableChange}
						dataSource={items}
						columns={[
							...columns,
							{
								title: 'Actions',
								dataIndex: 'actions',
								key: 'actions',
								width: 30,
								ellipsis: false,
								// flex: true,
								render: (_: any, record: any) => {
									return (
										<Flex justify="center">
											<Tooltip title="View details">
												<Button
													onClick={() =>
														openDrawer('view-copilot-video-details', record)
													}
													icon={<BookOutlined />}
												/>
											</Tooltip>
										</Flex>
									);
								},
							},
						]}
						rowKey={'id'}
						bordered
						scroll={{ x: 1100 }}
						pagination={{
							total,
							showSizeChanger: true,
							current: pagination.page,
							pageSizeOptions: [10, 25, 50],
							onChange: (page: number, pageSize: number) => {
								setPagination((cur) => ({ ...cur, page, limit: pageSize }));
							},
						}}
					/>
				</Flex>
			</div>
		</Spin>
	);
}
